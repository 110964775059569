<template>
  <template v-if="false || rows">
    <template v-for="(row, r) in rows" :key="row.id">
      <div>
        <result-group
          :rowNum="r"
          :desktop="desktop"
          :group="{
            ...row,
            location,
            seed,
          }"
          :isEstablished="isEstablished"
          :user="user"
          :trigger="searchTrigger"
          @needgeo="(callback) => $emit('needgeo', callback)"
          @selectstorefront="
            (s) => {
              selectedStorefront = s;
            }
          "
        />
      </div>
    </template>
    <storefront-modal
      :desktop="desktop"
      :selectedStorefront="selectedStorefront"
      :isEstablished="isEstablished"
      :user="user"
      @closemodal="
        () => {
          selectedStorefront = undefined;
        }
      "
      @selectstorefront="
        (s) => {
          selectedStorefront = s;
        }
      "
    />
  </template>
  <template v-else>
    <template v-for="(row, r) in new Array(12)" :key="r">
      <div>
        <result-group
          :rowNum="r"
          :desktop="desktop"
          :group="{
            ...row,
            items: [1, 2, 4, 5, 6, 8],
            location,
            seed,
          }"
          :isEstablished="isEstablished"
          :user="user"
          :trigger="searchTrigger"
          @needgeo="(callback) => $emit('needgeo', callback)"
          @selectstorefront="
            (s) => {
              selectedStorefront = s;
            }
          "
        />
      </div>
    </template>
  </template>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";
import { searchStorefronts } from "./storefrontGraphql";
import ResultGroup from "./ResultGroup";
import shuffle from "just-shuffle";
import StorefrontModal from "../storefront/StorefrontModal.vue";

//let test = "hello";

export default {
  name: "Results",
  components: {
    ResultGroup,
    StorefrontModal,
  },
  data() {
    return {
      fixedRows: [
        {
          id: "newToMoxey",
          title: "New to Moxey",
          loadingTitle: "Getting New Members ...",
          moreText: "More",
          moreText2: "New Members",
          predefinedSearchName: "searchNewStorefronts",
          resultCount: 12,
          searchFunction: searchStorefronts,
          index: "storefronts",
          description:
            "Moxey is growing! Say hello to these new members by making a purchase. ",
        },
        {
          id: "nearbyMembers",
          title: "Nearby Members",
          loadingTitle: "Finding Nearby Members ...",
          moreText: "More",
          moreText2: "Near You",
          predefinedSearchName: "searchNearbyStorefronts",
          resultCount: 12,
          searchFunction: searchStorefronts,
          geoRequired: true,
          index: "storefronts",
        },
        {
          id: "earnRewards",
          title: "Earn Extra Rewards",
          loadingTitle: "Finding Bonus Rewards ...",
          moreText: "More",
          moreText2: "Extra Rewards",
          predefinedSearchName: "searchStorefrontsByRewards",
          resultCount: 12,
          searchFunction: searchStorefronts,
          geoRequired: true,
          userRequired: true,
          index: "storefronts",
          description:
            "Moxey is growing! Say hello to these new members by making a purchase.",
        },
      ],

      news: [
        {
          id: "news",
          title: "News and Resources",
          isNews: true,
          items: [
            {
              name: "Good at 3,000+ small businesses",
              subtitle: "Click here for details and to check your balance.",
              hero: {
                key: "assets/homepage/GiftCardTile3.jpg",
              },
              onClickAction: () => {
                this.$router.push("/giftcards");
              },
            },
            {
              name: "Save Cash. Spend Moxey.",
              subtitle:
                "Find out how Moxey helps you save cash and earn new customers.",
              hero: {
                key: "assets/homepage/WhatIsMoxey.jpg",
              },
              onClickAction: () => {
                this.$emit("videoselected", "--sSfEpOk64");
              },
            },
          ],
        },
      ],
      extraRows: [
        {
          id: "sectors:Restaurants",
          title: "Restaurants",
          loadingTitle: "Getting Restaurants ...",
          moreText: "See more in",
          moreText2: "Restaurants",
          sectors: ["Restaurants"],
          type: "sectors",
          icon: "fad fa-fork-knife",
        },
        {
          id: "sectors:Automotive",
          title: "Auto Repair",
          loadingTitle: "Getting Auto Repair ...",
          moreText: "See more in",
          moreText2: "Auto Repair",
          sectors: ["Automotive"],
          type: "sectors",
          icon: "fad fa-car-wrench",
        },
        {
          id: "sectors:IT",
          title: "Information Technology",
          loadingTitle: "Getting IT ...",
          moreText: "See more in",
          moreText2: "IT",
          sectors: ["Information Technology"],
          type: "sectors",
          icon: "fad fa-computer",
        },
        {
          id: "categories:Pets",
          title: "Pets",
          loadingTitle: "Getting Pets ...",
          moreText: "See more in",
          moreText2: "Pets",
          categories: ["Pet Care"],
          type: "categories",
          icon: "fad fa-dog",
        },
        {
          id: "sectors:Travel",
          title: "Travel",
          loadingTitle: "Getting Travel ...",
          moreText: "See more in",
          moreText2: "Travel",
          sectors: ["Travel"],
          type: "sectors",
          icon: "fad fa-plane-engines",
        },
        {
          id: "sectors:Stores",
          title: "Stores",
          loadingTitle: "Getting Stores ...",
          moreText: "See more in",
          moreText2: "Stores",
          sectors: ["Retail"],
          type: "sectors",
          icon: "fad fa-bag-shopping",
        },
        {
          id: "categories:Dental",
          title: "Dental",
          loadingTitle: "Getting Dental ...",
          moreText: "See more in",
          moreText2: "Dental",
          categories: ["Dental"],
          type: "categories",
          icon: "fad fa-tooth",
        },
      ].map((e) => {
        return {
          ...e,
          predefinedSearchName: "searchStorefrontsByTags",
          index: "storefronts",
          searchFunction: searchStorefronts,
          resultCount: shuffle([3, 6, 12]).pop(),
        };
      }),

      rows: undefined,
      searchTrigger: 0,
      selectedStorefront: undefined,
      shuffleRowsTimeout: undefined,
    };
  },
  props: {
    location: Object,
    seed: String,
    trigger: Number,
    search: String,
    remixRows: Array,
    isEstablished: Boolean,
    user: Object,
    desktop: Boolean,
  },
  async mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapState({}),
  },
  watch: {
    trigger(post) {
      if (post && this.isEstablished) {
        this.shuffleRows();
      }
    },
    remixRows() {
      this.shuffleRows();
    },
  },
  methods: {
    shuffleRows() {
      clearTimeout(this.shuffleRowsTimeout);
      this.shuffleRowsTimeout = setTimeout(() => {
        let tempRows = shuffle([
          ...this.fixedRows.filter((f) => {
            if (!this.location && f.geoRequired) return false;
            if (!this.user && f.userRequired) return false;
            if (f.checkUserFn) f.checkUserFn(this.$user);

            return true;
          }),
          ...(this.remixRows || this.extraRows),
        ]);

        const collapsedItems = [];

        while (
          collapsedItems.length <
          Math.min(Math.floor(tempRows.length / 2), this.desktop ? 5 : 6)
        ) {
          tempRows = shuffle(tempRows);
          collapsedItems.push(tempRows.shift());
        }

        //Randomly collapsed rows
        const collapsed = {
          id: `collapsed:${Date.now()}`,
          title: "Curated for You",
          tileType: "circle",
          isCollection: true,
          items: collapsedItems.map((z) => {
            return {
              ...z,
              isCollection: true,
            };
          }),
        };

        this.rows = [
          ...tempRows.slice(0, 1),
          collapsed,
          ...this.news,
          ...tempRows.slice(1, 13),
        ];

        this.$nextTick(() => this.searchTrigger++);
      }, 500);
    },
    log(e) {
      console.log(e);
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped></style>
