<template>
  <TopNav
    :clickBars="openAccount"
    @clickedHome="
      () => {
        clearSearch++;
        search = '';
      }
    "
  />

<!--
  <div class="notice alert-warning border-bottom border-warning p-3" v-if="notice">
  <div class="d-flex fs-4">
      <div class="notice-text header-like grow">Special Announcement</div>
      <div class="notice-close">
        <button class="btn border-none p-0 text-secondary fs-4" @click="notice = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  -->

  <div class="outer">
    <div class="mb-3 d-flex">
      <div class="flex-fill">
        <search-bar
          :clear="clearSearch"
          :disabled="!isEstablished"
          :search="search"
          @focus="
            () => {
              if (!location) {
                triggerLocationSetter++;
                this.blur;
              }
            }
          "
          @barSubmit="
            (s) => {
              barSubmit = true;
              search = s;
            }
          "
          @update="
            (s) => {
              log('update?');
              search = s;
            }
          "
          @blur="
            (e) => {
              search = e.target.value;
              updatedRoute();
            }
          "
        />
      </div>
      <div class="d-none d-lg-flex ms-3" v-if="location">
        <control-button
          id="mapButton"
          :location="location"
          :search="search"
          :seed="seed"
          routeTo="map"
        >
          <mx-button
            btnClass="btn-outline-success"
            :padX="15"
            height="38"
            :pill="true"
          >
            <i class="fas fa-map-location-dot"></i> Map
          </mx-button>
        </control-button>
      </div>
      <div class="d-none d-lg-flex ms-3" v-else>
        <mx-button
          btnClass="btn-outline-success"
          :padX="15"
          height="38"
          :pill="true"
          @click="
            () => {
              triggerLocationSetter++;
              postLocationSetAction = clickMapButton;
            }
          "
        >
          <i class="fas fa-map-location-dot"></i> Map
        </mx-button>
      </div>
    </div>
    <location-setter
      :trigger="triggerLocationSetter"
      :memberZip="user?.member?.public?.zip"
      :isEstablished="isEstablished"
      :locationSelectCallback="locationSelectCallback"
      :seed="seed"
      :initialLocation="location"
      @location="
        (e) => {
          location = e;
          updatedRoute();
          mixerTrigger++;
          searchTrigger++;
          if (postLocationSetAction) postLocationSetAction();
        }
      "
    />
    <Transition name="slide-fade" mode="out-in">
      <div class="mt-3" v-if="!search">
        <featured-row
          :desktop="desktop"
          :location="location"
          @needgeo="
            (callback) => {
              locationSelectCallback = callback;
              triggerLocationSetter++;
            }
          "
        />
      </div>
      <div class="mt-3" v-else>
        <top-picks
          :desktop="desktop"
          :location="location"
          :search="search"
          :searchTrigger="searchTrigger"
          :isEstablished="isEstablished"
          :user="user"
          @remixtags="dispatchRemix"
          @needgeo="
            (callback) => {
              locationSelectCallback = callback;
              triggerLocationSetter++;
            }
          "
        />
      </div>
    </Transition>
    <div class="mt-3">
      <result-mixer
        :desktop="desktop"
        :search="search"
        :location="location"
        :seed="seed"
        :trigger="mixerTrigger"
        :isEstablished="isEstablished"
        :remixRows="remixTags"
        :user="user"
        @videoselected="(id) => (videoId = id)"
        @needgeo="
          (callback) => {
            locationSelectCallback = callback;
            triggerLocationSetter++;
          }
        "
      />
    </div>
  </div>

  <control-bar
    @openaccount="openAccount++"
    @promptLocation="
      () => {
        triggerLocationSetter++;
        postLocationSetAction = clickMapButton;
      }
    "
    class="position-fixed"
    :location="location"
    :search="search"
    :seed="seed"
  />

  <div id="footer" class="splash-section">
    <Footer />
  </div>

  <video-player :id="videoId" @openedvideo="videoId = undefined" />
</template>

<script>
import TopNav from "./TopNav.vue";
import Footer from "@/components/FrontPage/Footer/Footer.vue";
import SearchBar from "./SearchBar.vue";
import LocationSetter from "./LocationSetter.vue";
import VideoPlayer from "./VideoPlayer.vue";
import FeaturedRow from "./search/FeaturedRow.vue";
import { mapActions, mapState } from "vuex";
import ResultMixer from "./search/ResultMixer.vue";
import TopPicks from "./search/TopPicks.vue";
import ControlBar from "./ControlBar.vue";
import ControlButton from "./ControlButton.vue";
import MxButton from "../../components/Shared/MxButton.vue";

export default {
  name: "DirectoryHome",
  components: {
    Footer,
    TopNav,
    SearchBar,
    LocationSetter,
    FeaturedRow,
    ResultMixer,
    VideoPlayer,
    ControlBar,
    TopPicks,
    ControlButton,
    MxButton,
  },
  data() {
    return {
      barSubmit: false,
      search: this.$route.query.search,
      seed: this.$route.params.seed || Date.now(),
      page: 1,
      location: this.lat &&
        this.lon && {
          city: this.city,
          geo: {
            lat: this.lat,
            lon: this.lon,
          },
        },
      triggerLocationSetter: 0,
      locationSelectCallback: undefined,
      mixerTrigger: 0,
      searchTrigger: 0,
      videoId: undefined,
      openAccount: 0,
      remixTags: undefined,
      viewportWidth: window.innerWidth,
      postLocationSetAction: undefined,
      clearSearch: 0,
      notice: true,
    };
  },
  props: {
    city: String,
    lon: Number,
    lat: Number,
  },
  computed: {
    desktop() {
      return this.viewportWidth >= 768;
    },
    ...mapState({
      isEstablished: (state) => state.app.all.isEstablished,
      user: (state) => state.app.all.user,
      loggingIn: (state) => state.app.all.loggingIn,
    }),
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.viewportWidth = window.innerWidth;
    });

    this.$nextTick(() => {
      window.scrollTo(0, 0);
      window.prerenderReady = true;
    });
  },
  watch: {
    user(post, pre) {
      if (post?.SK1 !== pre?.SK1) {
        if (this.isEstablished && this.location && !this.search) {
          this.mixerTrigger++;
        }
        if (
          this.isEstablished &&
          (this.search?.length > 2 || this.barSubmit) &&
          this.location
        ) {
          this.searchTrigger++;
          this.barSubmit = false;
        }
      }
    },
    isEstablished(post) {
      if (post) {
        if (this.location && !this.search) this.mixerTrigger++;
        if ((this.search?.length > 2 || this.barSubmit) && this.location) {
          this.searchTrigger++;
          this.barSubmit = false;
        }
      }
    },
    search(post, pre) {
      if (
        (post.length > 2 || this.barSubmit) &&
        this.isEstablished &&
        post !== pre &&
        this.location
      ) {
        this.searchTrigger++;
        this.barSubmit = false;
      }
    },
  },
  //bump
  methods: {
    log(e) {
      console.log(e);
    },
    updatedRoute() {
      if (this.location)
        this.$router.replace({
          path: `/directory/home/${this.location?.city}/${this.location?.geo.lat}/${this.location?.geo.lon}/${this.seed}/`,
          query: {
            search: this.search,
          },
        });
    },
    async clickMapButton() {
      await this.$nextTick(() => {
        const mapButton = document.getElementById("mapButton");
        mapButton.click();
      });
    },
    dispatchRemix(t) {
      this.remixTags = t;
    },

    ...mapActions({}),
  },
  async beforeMount() {},
  async activated() {},
};
</script>

<style scoped>
.search-bar-holder {
  z-index: 10;
  width: 100%;
  height: 4rem;
  position: absolute;
  top: 233px;
  padding: 0 20px;
  transition: top 0.25s ease-out;
}

.hero-section {
  height: 260px;
  min-height: 260px;
}

.search-bar-holder.active {
  top: 75px;
  transition: top 0.2s ease-in;
}

#empty-section {
  height: 350px;
}

.outer {
  padding: 20px 20px 110px 20px;
}

@media (min-width: 475px) {
  #news-section {
    z-index: 1;
  }

  #search-section {
    z-index: 3;
  }

  #quick-cats {
    display: none;
    height: 321px;
    min-height: 321px;
  }

  .search-bar-holder {
    top: 135px;
  }

  .search-bar-holder.active {
    top: 135px;
  }

  #empty-section {
    height: 64px;
  }
}

@media (min-width: 768px) {
  .outer {
    padding: 20px 20px 0 20px;
  }
}
</style>

<style>
.notice {
}

.result-row {
  width: calc(100% + 40px);
  margin: 0 -20px;
  overflow-x: auto;
}

.result-row > .d-flex {
  padding: 0 20px;
}

.result-row > .d-flex.tiles {
  min-width: 1000px;
}

.slide-fade-enter-active {
  transition: all 0.35s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
  max-height: 1000px;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
