import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import Home from "../views/NewDirectory/Home.vue";
import ls from "local-storage";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Directory Home",
    component: Home,
    order: 1,
    meta: {
      title: "Moxey",
    },
  },

  {
    path: "/directory",
    name: "Directory Home 2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "DirectorySearch" */ "../views/NewDirectory/Home.vue"
      ),
    meta: {
      title: "Moxey Directory",
    },
  },

  {
    path: "/directory/home/:city/:lat/:lon/:seed/",
    name: "Directory Home 3",
    props: (route) => ({ ...route.params, ...route.query }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "DirectorySearch" */ "../views/NewDirectory/Home.vue"
      ),
    meta: {
      title: "Moxey Directory",
    },
  },

  {
    path: "/directory/map",
    name: "Directory Map 1",
    props: (route) => ({ ...route.params, ...route.query }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "DirectorySearch" */ "../views/NewDirectory/Map.vue"
      ),
    meta: {
      title: "Moxey Map",
    },
  },

  {
    path: "/directory/map/:city/:lat/:lon/:seed/",
    name: "Directory Map 2",
    props: (route) => ({ ...route.params, ...route.query }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "DirectorySearch" */ "../views/NewDirectory/Map.vue"
      ),
    meta: {
      title: "Moxey Map",
    },
  },

  {
    path: "/directory/storefronts/:storefrontName/:storefrontId",
    name: "Moxey Member",
    props: (route) => ({ ...route.params, ...route.query }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "DirectoryMember" */ "../views/NewDirectory/storefront/StorefrontPage.vue"
      ),
    meta: {
      title: "Moxey Directory",
    },
  },

  //Old member path
  {
    path: "/directory/member/:memberId/",
    name: "Directory Member",
    props: (route) => {
      return { ...route.params, ...route.query };
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "DirectoryMember" */ "../views/Directory/DirectoryMember.vue"
      ),
    meta: {
      title: "Moxey Directory",
    },
  },

  {
    path: "/directory/search/:index/:predefinedSearchName/:city/:lat/:lon/:seed/:page/",
    name: "Search Results",
    props: (route) => ({ ...route.params, ...route.query }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "DirectoryMember" */ "../views/NewDirectory/search/Search.vue"
      ),
    meta: {
      title: "Moxey Directory",
    },
  },

  // {
  //   path: "/directory/member/:id/",
  //   name: "Directory Member",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "DirectoryMember" */ "../views/Directory/DirectoryMember.vue"
  //     ),
  //   meta: {
  //     title: "Moxey Directory",
  //   },
  // },

  {
    path: "/directory/explore/:city/:lat/:lon/:seed/",
    name: "Categories 2",
    props: (route) => ({ ...route.params, ...route.query }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Categories" */ "../views/NewDirectory/Categories.vue"
      ),
    meta: {
      title: "Explore",
    },
  },

  {
    path: "/directory/explore",
    name: "Categories",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Categories" */ "../views/Directory/Categories.vue"
      ),
    meta: {
      title: "Explore",
    },
  },

  {
    path: "/termsandconditions",
    name: "Terms and Conditions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "TermsAndConditions" */ "../views/Account/TermsAndConditions.vue"
      ),
    meta: {
      title: "Moxey Terms and Conditions",
    },
  },

  {
    path: "/debug",
    name: "debug",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Debug" */ "../views/Directory/Debug.vue"),
    meta: {
      title: "Debug",
    },
  },

  {
    path: "/admin",
    name: "Admin Landing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AdminLanding" */ "../views/Admin/Landing.vue"
      ),
    meta: {
      title: "Site Admin",
      checkLoggedIn: true,
    },
  },

  {
    path: "/admin/events",
    name: "Events",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Events" */ "../views/Admin/Events.vue"),
    meta: {
      title: "Event Admin",
      checkLoggedIn: true,
    },
  },

  {
    path: "/admin/hero",
    name: "Hero Image",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "HeroImage" */ "../views/Admin/HeroImg.vue"),
    meta: {
      title: "Hero Image Admin",
      checkLoggedIn: true,
    },
  },

  {
    path: "/admin/users",
    name: "Users",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/Admin/Users.vue"),
    meta: {
      title: "User Admin",
      checkLoggedIn: true,
    },
  },

  {
    path: "/admin/tnc",
    name: "Terms and Conditions Setup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "TermsAndConditionsSetup" */ "../views/Admin/TermsSetter.vue"
      ),
    meta: {
      title: "Terms & Conditions",
      checkLoggedIn: true,
    },
  },

  {
    path: "/admin/points",
    name: "Points Setup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "TermsAndConditionsSetup" */ "../views/Admin/PointsEarning.vue"
      ),
    meta: {
      title: "Community Rewards Earning",
      checkLoggedIn: true,
    },
  },

  {
    path: "/account",
    name: "Account",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account/Account.vue"),
    meta: {
      title: "My Account",
      checkLoggedIn: true,
    },
  },

  {
    path: "/notifications",
    name: "Notifications",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account/Account.vue"),
    meta: {
      title: "My Notifications",
      checkLoggedIn: true,
    },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account/Account.vue"),
    meta: {
      title: "My Dashboard",
      checkLoggedIn: true,
    },
  },

  {
    path: "/transactions/advanced",
    name: "Advanced Transactions View",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Account" */ "../views/Account/AdvTransactions.vue"
      ),
    meta: {
      title: "Advanced Transactions Report",
      checkLoggedIn: true,
    },
  },

  {
    path: "/transactions",
    name: "Transactions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account/Account.vue"),
    meta: {
      title: "My Transactions",
      checkLoggedIn: true,
    },
  },

  {
    path: "/documents",
    name: "Documents",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account/Account.vue"),
    meta: {
      title: "My Documents",
      checkLoggedIn: true,
    },
  },

  {
    path: "/mp/:moxeyPayCode",
    name: "MoxeyPay",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MoxeyPay" */ "../views/MoxeyPay/MoxeyPay.vue"
      ),
    meta: {
      title: "MoxeyPay",
      fullScreen: true,
    },
  },

  {
    path: "/mpos/:accessCode",
    name: "MoxeyPayPOSNoId",
    alias: ["/mpos/:accessCode/:legacyContactId", "/mpos"],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: (route) => ({ ...route.params, ...route.query }),
    component: () =>
      import(
        /* webpackChunkName: "MoxeyPay" */ "../views/NewDirectory/vpos/VirtualPOS.vue"
      ),
    meta: {
      title: "Moxey Point of Sale",
      fullScreen: true,
      noLeftBar: true,
    },
  },

  {
    path: "/vposreceipt",
    name: "MoxeyPayPOSreceipt",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: (route) => ({ ...route.params, ...route.query }),
    component: () =>
      import(
        /* webpackChunkName: "MoxeyPay" */ "../views/NewDirectory/vpos/Receipt.vue"
      ),
    meta: {
      title: "Moxey Receipt",
      fullScreen: true,
      noLeftBar: true,
    },
  },

  // {
  //   path: "/mpos/:moxeyPayCode/:virtualPOSContactId",
  //   name: "MoxeyPayPOS",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "MoxeyPay" */ "../views/MoxeyPay/MoxeyPayPOS.vue"
  //     ),
  //   meta: {
  //     title: "MoxeyPay Virtual POS",
  //     fullScreen: true,
  //   },
  // },

  {
    path: "/print/statement",
    name: "Print Statement",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PrintStatement" */ "../views/Print/Statement.vue"
      ),
    meta: {
      fullScreen: true,
    },
  },

  {
    path: "/giftcards",
    name: "Gift Cards Landing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "GiftCards" */ "../views/GiftCards.vue"),
    meta: {
      title: "Moxey Gift Cards",
      fullScreen: true,
    },
  },

  {
    path: "/activate",
    name: "Activate a Moxey Card",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "GiftCards" */ "../views/ActivateCard.vue"),
    meta: {
      title: "Activate a Moxey Card",
      fullScreen: true,
      checkLoggedIn: true,
    },
  },

  {
    path: "/background",
    name: "Sample Background",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Background" */ "../views/Background.vue"),
    meta: {
      title: "Sample Background",
      fullScreen: true,
    },
  },

  {
    path: "/verify/:verificationCode",
    name: "Email Verification",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "EmailVerify" */ "../views/EmailVerify.vue"),
    meta: {
      title: "Email Verification",
      fullScreen: true,
    },
  },

  {
    path: "/join/:inviteCode",
    name: "Join Moxey",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Join" */ "../views/Join/Join.vue"),
    meta: {
      title: "Join Moxey",
      fullScreen: true,
    },
  },

  {
    path: "/rewards",
    name: "Community Rewards",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Rewards" */ "../views/Rewards.vue"),
    meta: {
      title: "Community Rewards",
      fullScreen: true,
      checkLoggedIn: true,
    },
  },

  {
    path: "/MoxeyPaySetup",
    name: "MoxeyPay Setup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MoxeyPaySetup" */ "../views/MoxeyPaySetup/MoxeyPaySetupPage.vue"
      ),
    meta: {
      title: "MoxeyPay Setup",
      checkLoggedIn: true,
    },
  },

  {
    path: "/incentives",
    name: "Employee Incentive Setup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MoxeyPaySetup" */ "../views/NewDirectory/Incentives/IncentiveSetup.vue"
      ),
    meta: {
      title: "Employee Incentive Setup",
      checkLoggedIn: true,
    },
  },

  {
    path: "/MoxeyPayPOSSetup",
    name: "MoxeyPay Virtual Point of Sale Setup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MoxeyPayPOSSetup" */ "../views/NewDirectory/vpos/Setup.vue"
      ),
    meta: {
      title: "MoxeyPay Virtual Point of Sale Setup",
      checkLoggedIn: true,
      fullScreen: true,
      noLeftBar: true,
    },
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      if (to.fullPath.substring(0, 2) === "/#") {
        return { path: to.fullPath.replace("/#", "") };
      } else {
        return { path: "/" };
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.name.includes("Directory Home"))
      // always scroll to top
      return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.app.all.isEstablished) {
    store.dispatch("app/updateStateOnLoad");
  }
  if (
    to.meta.checkLoggedIn &&
    !store.state.app.all.testMode &&
    !store.state.app.all.user &&
    !ls.get("accessToken")
  ) {
    next("/");
    store.commit("app/update", {
      leftBarComp: {
        name: "Login",
        payload: {
          goto: to,
        },
      },
    });
  } else {
    store.commit("app/update", {
      lastPage: {
        name: from.name,
        path: from.path,
        query: from.query,
        fullPath: from.fullPath,
      },
    });
    if (to.name === "Directory Search") {
      const title =
        (to.query.businessType !== "false" && to.query.businessType) ||
        (to.query.productType !== "false" && to.query.productType) ||
        "Search";
      to.meta.title = `Moxey Directory - ${title}`;
    } else if (
      to.name === "Directory Member" &&
      store.state.app.all.ogMeta.title
    ) {
      const ogMeta = store.state.app.all.ogMeta;
      if (ogMeta.title) {
        to.meta.title = ogMeta.title;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", ogMeta.title);
        document
          .querySelector('meta[property="og:url"]')
          .setAttribute("content", `https://app.moxeyusa.com/#${to.fullPath}`);
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute("content", ogMeta.img);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", ogMeta.desc);
      }
    }
    document.title = to.meta.title;

    document.activeElement.blur();

    next();
  }
});

router.afterEach(() => {});

trackRouter(router, {
  template(to) {
    return {
      page_title: (to.meta && to.meta.title) || to.name,
      page_path: to.path,
    };
  },
});

export default router;
